import React, { useState } from 'react';
import './styles_new.css';
import { useNavigate } from 'react-router-dom';

function Signup() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
  };

  const redirectToHome = () => {
    navigate('/');
  };
  const redirectToLogin = () => {
    navigate('/login');
  };
  return (
    <div className="signup">
      <header>
        <div className="website-title">Syllabix</div>
        <button className="hamburger-menu" onClick={toggleMenu}>
          &#9776; {/* Hamburger icon */}
        </button>
        <div className={`header-buttons ${isMenuOpen ? 'open' : ''}`}>
          <button className="btn" onClick={redirectToHome}>Home</button>
          <button className="btn" onClick={redirectToLogin}>LogIn</button>
        </div>
      </header>
      <div className="content-part">
        <h1>Sign Up</h1>
        <form>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input type="text" id="firstName" name="firstName" required />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input type="text" id="lastName" name="lastName" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" required />
          </div>
          <div className="form-group">
            <label htmlFor="dob">Date of Birth</label>
            <input type="date" id="dob" name="dob" required />
          </div>
          <div className="form-group">
            <label htmlFor="streetAddress">Street Address</label>
            <input type="text" id="streetAddress" name="streetAddress" required />
          </div>
          <div className="form-group">
            <label htmlFor="city">City</label>
            <input type="text" id="city" name="city" required />
          </div>
          <div className="form-group">
            <label htmlFor="state">State</label>
            <select id="state" name="state" required>
              <option value="Alabama">Alabama</option>
              <option value="Alaska">Alaska</option>
              {/* Add more state options here */}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="zipcode">Zipcode</label>
            <input type="text" id="zipcode" name="zipcode" required />
          </div>
          <div className="form-group">
            <label htmlFor="role">Role</label>
            <select id="role" name="role" required>
              <option value="student">Student</option>
              <option value="instructor">Instructor</option>
              <option value="administrator">Administrator</option>
              <option value="qaOfficer">QA Officer</option>
              <option value="programCoordinator">Program Coordinator</option>
            </select>
          </div>
          <div className="form-group">
            <button className="btn" type="submit">Sign Up</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signup;
