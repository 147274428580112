import React, { useState } from 'react';
import './styles_new.css';
import { useNavigate } from 'react-router-dom';

function Admin() {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const redirectToStudent = () => {
        navigate('/student');
      };
      const redirectToHome = () => {
        navigate('/');
      };
      const redirectToqa = () => {
        navigate('/qa');
      };
      const redirectToInstructor = () => {
        navigate('/instructor');
      };
      const redirectToProcoord = () => {
        navigate('/programcoordinator');
      };
  return (
    <div>
      <header>
                <div className="website-title">Syllabix</div>
                <button
                    className="hamburger-menu"
                    onClick={toggleMenu}
                >
                    &#9776; {/* Unicode for the hamburger menu icon */}
                </button>
                <div className={`header-buttons ${isMenuOpen ? 'open' : ''}`}>
                    <button className="btn" onClick={redirectToHome}>Home</button>
                    <button className="btn" onClick={redirectToStudent}>Student</button>
                    <button className="btn" onClick={redirectToInstructor}>Instructor</button>
                    <button className="btn" onClick={redirectToProcoord}>ProgramCoOrdinator</button>
                    <button className="btn" onClick={redirectToqa}>QA</button>
                </div>
            </header>
      <div className="content-part-home" style={{ marginTop: '1px' }}>
        <h1>Students</h1>
        <div className="h-buttons">
          <button className="btn" style={{ width: '30%' }}>Student Management</button>
          <button className="btn" style={{ width: '30%' }}>Manage Courses</button>
          <button className="btn" style={{ width: '30%' }}>Permissions</button>
        </div>
        <div className="h-buttons">
          <button className="btn" style={{ width: '30%' }}>Edit profile</button>
          <button className="btn" style={{ width: '30%' }}>Add course</button>
          <button className="btn" style={{ width: '30%' }}>Delete course</button>
        </div>
        <h1>Instructors</h1>
        <div className="h-buttons">
          <button className="btn" style={{ width: '30%' }}>Personal info</button>
          <button className="btn" style={{ width: '30%' }}>Manage Courses</button>
          <button className="btn" style={{ width: '30%' }}>Grading</button>
        </div>
        <div className="h-buttons">
          <button className="btn" style={{ width: '30%' }}>Inquiries</button>
          <button className="btn" style={{ width: '30%' }}>Feedback</button>
          <button className="btn" style={{ width: '30%' }}>Students progress</button>
        </div>
        <h1>Coordinator</h1>
        <div className="h-buttons">
          <button className="btn" style={{ width: '30%' }}>Permissions</button>
          <button className="btn" style={{ width: '30%' }}>Permissions</button>
          <button className="btn" style={{ width: '30%' }}>Manage Courses</button>
        </div>
        <div className="h-buttons">
          <button className="btn" style={{ width: '30%' }}>Coordinators</button>
          <button className="btn" style={{ width: '30%' }}>Student Reports</button>
          <button className="btn" style={{ width: '30%' }}>Add/Edit/Delete Students</button>
        </div>
        <h1>QAO</h1>
        <div className="h-buttons">
          <button className="btn" style={{ width: '30%' }}>QAO Management</button>
          <button className="btn" style={{ width: '30%' }}>Course assignment</button>
          <button className="btn" style={{ width: '30%' }}>Permission handling</button>
        </div>
        <div className="h-buttons">
          <button className="btn" style={{ width: '30%' }}>QAO reports</button>
          <button className="btn" style={{ width: '30%' }}>Performance reviews</button>
          <button className="btn" style={{ width: '30%' }}>Recommandations</button>
        </div>
      </div>
      <div className="chat-popup" id="myForm">
        <h1>Chat</h1>
        <div className="form-group">
          <label htmlFor="msg">Message</label>
          <input type="text" id="message" name="message" required />
        </div>
        <div className="form-group">
          <button className="btn" type="submit">Submit</button>
        </div>
      </div>
    </div>
  );
}

export default Admin;
