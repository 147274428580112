import React, { useState } from 'react';
import './styles_new.css';
import { useNavigate } from 'react-router-dom';

function QualityAssurance() {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const redirectToStudent = () => {
        navigate('/student');
      };
      const redirectToHome = () => {
        navigate('/');
      };
      const redirectToqa = () => {
        navigate('/qa');
      };
      const redirectToInstructor = () => {
        navigate('/instructor');
      };
      const redirectToProcoord = () => {
        navigate('/programcoordinator');
      };
  return (
    <div>
      <header>
        <div className="website-title">Syllabix <span style={{ fontSize: 'small' }}>Quality Assurance</span></div>
        <button className="hamburger-menu" onClick={toggleMenu}>
          &#9776; {/* Hamburger icon */}
        </button>
        <div className={`header-buttons ${isMenuOpen ? 'open' : ''}`}>
          <button className="btn" onClick={redirectToHome}>Home</button>
          <button className="btn" onClick={redirectToStudent}>Student</button>
          <button className="btn" onClick={redirectToInstructor}>Instructor</button>
          <button className="btn" onClick={redirectToProcoord}>ProgramCoOrdinator</button>
          <button className="btn">Sign Out</button>
        </div>
      </header>
      <div className="content-part-home" style={{ marginTop: '100px' }}> <br />
        <div className="left" style={{ float: 'right', width: '50%' }}>
          <button className="btn" style={{ width: '80%' }}>Feedbacks</button> <br /> <br /> <br />
          <button className="btn" style={{ width: '80%' }}>Instructor Improvements</button> <br /> <br /> <br />
          <button className="btn" style={{ width: '80%' }}>Accreditation</button> <br /> <br /> <br />
          <button className="btn" style={{ width: '80%' }}>Evaluate Program</button> <br /> <br /> <br />
        </div>
        <div className="right" style={{ width: '50%' }}>
          <button className="btn" style={{ width: '80%' }}>Evaluate Curriculum </button> <br /> <br /> <br />
          <button className="btn" style={{ width: '80%' }}>Tools For Assessment </button> <br /> <br /> <br />
          <button className="btn" style={{ width: '80%' }}>Reporting Tool</button> <br /> <br /> <br />
          <button className="btn" style={{ width: '80%' }}>Quality Standards</button> <br /> <br /> <br />
        </div>
      </div>
      <div className="chat-popup" id="myForm">
        <h1>Chat</h1>
        <div className="form-group">
          <label htmlFor="msg">Message</label>
          <input type="text" id="message" name="message" required />
        </div>
        <div className="form-group">
          <button className="btn" type="submit">Submit</button>
        </div>
      </div>
    </div>
  );
}

export default QualityAssurance;
