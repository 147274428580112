import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/HomePage';
import Login from './components/LogIn'
import Signup from './components/signup';
import QualityAssurance from './components/qualityassurance';
import Instructor from './components/instructor';
import ProgramCoordinator from './components/programcoordinator';
import Admin from './components/admin';
import PersonalInformation from './components/PersonalInformation';
import Student from './components/student';
import ForgotPassword from './components/forgetpwd';
import ContactUs from './components/contactus';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/student" element={<Student />} />
        <Route path="/programcoordinator" element={<ProgramCoordinator />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/qa" element={<QualityAssurance />} />
        <Route path="/instructor" element={<Instructor />} />
        <Route path="/pinfo" element={<PersonalInformation />} />
        <Route path="/forgetpwd" element={<ForgotPassword />} />
        <Route path="/contactus" element={<ContactUs />} />
      </Routes>
    </Router>
  );
}

export default App;
