import React, { useState } from 'react';
import './styles_new.css';
import { useNavigate } from 'react-router-dom';

function Student() {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
  
    const redirectToHome = () => {
      navigate('/');
    };
    const redirectToLogin = () => {
        navigate('/login');
    };
    const redirectToStudent = () => {
      navigate('/student');
    };
    const redirectToqa = () => {
      navigate('/qa');
    };
    const redirectToInstructor = () => {
      navigate('/instructor');
    };
    const redirectToProcoord = () => {
      navigate('/programcoordinator');
    };
    const redirectToAdmin = () => {
      navigate('/admin');
    };
    
  return (
    <div>
      <header>
        <div className="website-title">Syllabix</div>
        <button className="hamburger-menu" onClick={toggleMenu}>
          &#9776; {/* Hamburger icon */}
        </button>
        <div className={`header-buttons ${isMenuOpen ? 'open' : ''}`}>
          <button className="btn" onClick={redirectToHome}>Home</button>
          <button className="btn" onClick={redirectToStudent}>Student</button>
          <button className="btn" onClick={redirectToInstructor}>Instructor</button>
          <button className="btn" onClick={redirectToProcoord}>ProgramCoOrdinator</button>
          <button className="btn" onClick={redirectToAdmin}>Admin</button>
          <button className="btn" onClick={redirectToqa}>QA</button>
          <button className="btn" onClick={redirectToLogin}>Login</button>
        </div>
      </header>

      <h1>Student-DashBoard</h1>

      <div className="group-button">
        <button type="Announcements" className="btn">Announcements</button>
        <button type="Files" className="btn">Files</button>
        <button type="Grades" className="btn">Grades</button>
        <button type="All-Courses" className="btn">All-courses</button>
        <button type="SignOut" className="btn">Sign-out</button>
      </div>

      <div className="container">
        <div className="box1">
          <div className="user-details">
            <h1>Student Details:</h1>
            <form id="studentDetailsForm">
              <div className="detail">
                <label htmlFor="name">Name:</label>
                <input type="text" id="name" placeholder='Enter Your Name' className="form-input-size" required />
              </div>

              <div className="detail">
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" placeholder='Enter Your E-mail id' className="form-input-size" required />
              </div>

              <div className="detail">
                <label htmlFor="ContactNumber">Contact Number:</label>
                <input type="text" id="ContactNumber" placeholder='Enter Your Contact Number' className="form-input-size" required />
              </div>

              <h3>Address:</h3>
              <div className="detail">
                <label htmlFor="AddressLine1">Address Line1:</label>
                <input type="text" id="AddressLine1" placeholder='Street 1' className="form-input-size" required />
              </div>

              <div className="detail">
                <label htmlFor="AddressLine2">Address Line2:</label>
                <input type="text" id="AddressLine2" placeholder='Street 2' className="form-input-size" required />
              </div>

              <div className="detail">
                <label htmlFor="cityDistrict">City/District:</label>
                <input type="text" id="cityDistrict" placeholder='City' className="form-input-size" required />
              </div>

              <div className="detail">
                <label htmlFor="State">State:</label>
                <input type="text" id="State" placeholder='City' className="form-input-size" required />
              </div>

              <div className="detail">
                <label htmlFor="postalCode">Postal Code:</label>
                <input type="text" id="postalCode" placeholder='Postal Code' className="form-input-size" required />
              </div>

              <div className="detail">
                <label htmlFor="Country">Country:</label>
                <input type="text" id="Country" placeholder='Country' className="form-input-size" required />
              </div>

              <div>
                <label htmlFor="Gender">Gender:</label>
                <select name="Gender" id="Gender">
                  <option value="Default">Default</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div>
                <label htmlFor="BloodGroup">Blood Group:</label>
                <select name="BloodGroup" id="BloodGroup">
                  <option value="Default">Default</option>
                  <option value="A+">A+</option>
                  <option value="A-">A-</option>
                  <option value="B+">B+</option>
                  <option value="B-">B-</option>
                  <option value="O+">O+</option>
                  <option value="O-">O-</option>
                  <option value="AB+">AB+</option>
                  <option value="AB-">AB-</option>
                </select>
              </div>

              <div className="detail">
                <div className="button-container">
                  <button type="Save" className="btn">Save</button>
                  <button type="Edit" className="btn">Edit</button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <h3>Courses:</h3>
            <div class="box2">
                <div class="ibox3">
                    <div class = "course-details">
                        <div class ="detail"> 
                            <div class="section">
                                <div class="course-title">
                                    <p>Data Structures and Algorithms</p>
                                    <div class ="course-content">
                                        <p><strong>Instructor:</strong>Jennifer Kane</p>
                                        <p><strong>Class:</strong>CSE 5829-001,ONCAMPUS</p>
                                        <p><strong>Course Length:</strong>16 weeks</p>
                                        <p><strong>Room Number:</strong>AH 108</p>
                                        <p><strong>Days:</strong>Monday,Tuesday</p>
                                        <p><strong>Time:</strong>1:00PM to 2:20PM</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>

                <div class="ibox3">
                    <div class = "course-details">
                        <div class ="detail"> 
                            <div class="section">
                                <div class="course-title">
                                    <p>Data Database Systems</p>
                                    <div class ="course-content">
                                        <p><strong>Instructor:</strong>Michael Watt</p>
                                        <p><strong>Class:</strong>CSE 5380-005,ONCAMPUS</p>
                                        <p><strong>Course Length:</strong>16 weeks</p>
                                        <p><strong>Room Number:</strong>CN 328</p>
                                        <p><strong>Days:</strong>Monday,Wednessay</p>
                                        <p><strong>Time:</strong>7:00PM to 8:20PM</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="ibox3">
                    <div class = "course-details">
                        <div class ="detail"> 
                            <div class="section">
                                <div class="course-title">
                                    <p>Artificial Intelligence</p>
                                    <div class ="course-content">
                                        <p><strong>Instructor:</strong>Alex Jones</p>
                                        <p><strong>Class:</strong>CSE 5738-003,ONCAMPUS</p>
                                        <p><strong>Course Length:</strong>16 weeks</p>
                                        <p><strong>Room Number:</strong>SEAR 437</p>
                                        <p><strong>Days:</strong>Thursday</p>
                                        <p><strong>Time:</strong>2:00PM to 5:00PM</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
          
                </div>
                
                <div class="container">
                    <div class="first">
                        <div class="exam-details">
                            <h2>Exams and Assignments:</h2>
                            <p>Exam1-Database Systems:Due sept 30 11:59PM</p>
                            <p>Assignment2-Machine Learning :Due oct 5 11:59PM</p> 
                            <button type="More" class="btn">More</button>
                        </div>
                    </div>
    
                <div class="second">
                    <div class="exam-details">
                        <h2>Reports:</h2>
                        <p>Database Systems:A+</p>
                        <p>WebData Managemnet:A</p>
                        <p>Machine Learning:B+</p>
                        <button type="Report" class="btn">Reports</button>
                     </div>
                </div>
                <div class="third">

                </div>
            </div>
                
        </div> 
      <div className="chat-popup" id="myForm">
        <h1>Chat</h1>
        <div className="form-group">
          <label htmlFor="msg">Message</label>
          <input type="text" id="message" name="message" required />
        </div>
        <div className="form-group">
          <button className="btn" type="submit">Submit</button>
        </div>
      </div>
    </div>
    </div>  
  );
}

export default Student;
