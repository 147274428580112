import React, { useState } from 'react';
import './styles_new.css';
import { useNavigate } from 'react-router-dom';

function ProgramCoordinator() {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const redirectToHome = () => {
        navigate('/');
      };
      const redirectToInstructor = () => {
        navigate('/instructor');
      };
      const redirectToAdmin = () => {
        navigate('/admin');
      };
    
  return (
    <div>
      <header>
        <div className="website-title">Syllabix <span style={{ fontSize: 'small' }}>Program Coordinator</span></div>
        <button className="hamburger-menu" onClick={toggleMenu}>
          &#9776; {/* Hamburger icon */}
        </button>
        <div className={`header-buttons ${isMenuOpen ? 'open' : ''}`}>
          <button className="btn" onClick={redirectToHome}>Home</button>
          <button className="btn" onClick={redirectToAdmin}>Admin</button>
          <button className="btn" onClick={redirectToInstructor}>Instructor</button>
          <button className="btn">Stakeholders</button>
          <button className="btn">Sign Out</button>
        </div>
      </header>
      <div className="content-part-home" style={{ marginTop: '100px' }}>
        <br />
        <div className="left" style={{ float: 'right', width: '50%' }}>
          <button className="btn" style={{ width: '80%' }}>Tools For Communication</button>
          <br /><br /><br />
          <button className="btn" style={{ width: '80%' }}>Collaborations</button>
          <br /><br /><br />
          <button className="btn" style={{ width: '80%' }}>Guidance for Instructor</button>
          <br /><br /><br />
          <button className="btn" style={{ width: '80%' }}>Support for Instructor</button>
          <br /><br /><br />
        </div>
        <div className="right" style={{ width: '50%' }}>
          <button className="btn" style={{ width: '80%' }}>Individual Students Evaluate</button>
          <br /><br /><br />
          <button className="btn" style={{ width: '80%' }}>Inquiries For programs</button>
          <br /><br /><br />
          <button className="btn" style={{ width: '80%' }}>Student monitoring Tools</button>
          <br /><br /><br />
          <button className="btn" style={{ width: '80%' }}>Concerns</button>
          <br /><br /><br />
        </div>
      </div>
      <div className="chat-popup" id="myForm">
        <h1>Chat</h1>
        <div className="form-group">
          <label htmlFor="msg">Message</label>
          <input type="text" id="message" name="message" required />
        </div>
        <div className="form-group">
          <button className="btn" type="submit">Submit</button>
        </div>
      </div>
    </div>
  );
}

export default ProgramCoordinator;
