import React, { useState } from 'react';
import './styles_new.css';
import { useNavigate } from 'react-router-dom';

function Instructor() {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const redirectToStudent = () => {
        navigate('/student');
      };
      const redirectToHome = () => {
        navigate('/');
      };
      const redirectToPersonalInfo = () => {
        navigate('/pinfo');
      };
  return (
    <div>
      <header>
                <div className="website-title">Syllabix</div>
                <button className="hamburger-menu" onClick={toggleMenu}>
                    &#9776; {/* Hamburger icon */}
                </button>
                <div className={`header-buttons ${isMenuOpen ? 'open' : ''}`}>
                    <button className="btn" onClick={redirectToHome}>Home</button>
                    <button className="btn" onClick={redirectToStudent}>Student</button>
                    <button className="btn" onClick={redirectToPersonalInfo}>Personal Information</button>
                    <button className="btn">Feedback</button>
                    {/* Add additional menu items here */}
                </div>
            </header>
      <h1>Instructor Dashboard</h1>
      <div className="group-button">
        <button className="btn" type="Group Creation">Group Creation</button>
        <button className="btn" type="Course Creation">Course Creation</button>
        <button className="btn" type="Exam Creation">Exam Creation</button>
        <button className="btn" type="Student Grading">Student Grading</button>
      </div>
      <div className="container">
        <div className="box">
          <h2>Action Menu</h2>
          <p><a href="assign_exam">Assign Exam</a></p>
          <p><a href="create_announcement">Create Announcement</a></p>
          <p><a href="create_assignment">Create Assignment</a></p>
          <p><a href="modify_modules">Modify Modules</a></p>
        </div>
        <div className="box">
          <h2>Created Exams</h2>
          <p><a href="Exam 1">Exam 1</a>
            <button className="edit-btn">Edit</button>
            <button className="delete-btn">Delete</button></p>
          <p><a href="Exam 2">Exam 2</a>
            <button className="edit-btn">Edit</button>
            <button className="delete-btn">Delete</button></p>
          <p><a href="Exam 3">Exam 3</a>
            <button className="edit-btn">Edit</button>
            <button className="delete-btn">Delete</button></p>
          <p><a href="Exam 4">Exam 4</a>
            <button className="edit-btn">Edit</button>
            <button className="delete-btn">Delete</button></p>
        </div>
        <div className="box">
          <h2>Student Grades</h2>
          <p><a href="Carry, John">Carry, John</a>
            <button className="delete-btn">Track Progress</button></p>
          <p><a href="Thomas, Trevor">Thomas, Trevor</a>
            <button className="delete-btn">Track Progress</button></p>
          <p><a href="Roy, Kishan">Roy, Kishan</a>
            <button className="delete-btn">Track Progress</button></p>
          <p><a href="Sing, Anisha">Sing, Anisha</a>
            <button className="delete-btn">Track Progress</button></p>
        </div>
        <div className="chat-popup" id="myForm">
          <h1>Chat</h1>
          <div className="form-group">
            <label htmlFor="msg">Message</label>
            <input type="text" id="message" name="message" required />
          </div>
          <div className="form-group">
            <button className="btn" type="submit">Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Instructor;
