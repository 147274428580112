/*import ContactUs from './App'; */
import React, { useState } from 'react';
import './styles_new.css';
import { useNavigate } from 'react-router-dom';


function ContactUs() {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
  
    const redirectToHome = () => {
      navigate('/');
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        // Add code to handle form submission (e.g., send data to server)
        console.log('Form submitted');
    }

    return (
        <div>
        <header>
                <div className="website-title">Syllabix</div>
                <button className="hamburger-menu" onClick={toggleMenu}>
                    &#9776; {/* Hamburger icon */}
                </button>
                <div className={`header-buttons ${isMenuOpen ? 'open' : ''}`}>
                    <button className="btn" onClick={redirectToHome}>Home</button>
                    {/* Add additional menu items here */}
                </div>
            </header>
        <div className="content-part">
            <h1>Reach Out To Us</h1>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" id="name" name="name" required />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label><br />
                    <input type="email" id="email" name="email" required />
                </div>
                <div className="form-group">
                    <label htmlFor="phonenumber">Phone Number</label>
                    <input type="text" id="phonenumber" name="phonenumber" required />
                </div>
                <div className="form-group">
                    <label htmlFor="subject">Subject</label>
                    <input type="text" id="subject" name="subject" required />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label><br />
                    <input type="text" id="message" name="message" required />
                </div>
                <div className="form-group">
                    <button className="btn" type="submit">Submit</button>
                </div>
            </form>
        </div>
        </div>
    );
}

export default ContactUs;
