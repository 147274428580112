import React, { useState } from 'react';
import './styles_new.css';
import { useNavigate, Link } from 'react-router-dom';

function redirectToBlog() {
    window.open('http://bxo2365.uta.cloud/', '_blank');
  }

function HomePage() {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };
  const navigate = useNavigate();

  const redirectToLogin = () => {
    navigate('/login');
  };
  const redirectToSignUp = () => {
    navigate('/signup');
  };
  const redirectToStudent = () => {
    navigate('/student');
  };
  const redirectToqa = () => {
    navigate('/qa');
  };
  const redirectToInstructor = () => {
    navigate('/instructor');
  };
  const redirectToProcoord = () => {
    navigate('/programcoordinator');
  };
  const redirectToAdmin = () => {
    navigate('/admin');
  };

  return (
    <div>
      <header>
        <div className="website-title">Syllabix</div>
        {/* Hamburger menu button for mobile */}
        <button className="hamburger-menu" onClick={toggleMenu}>
             &#9776; {/* Hamburger icon */}
        </button>
        <div className={`header-buttons ${isMenuOpen ? 'open' : ''}`}>
          <button className="btn" onClick={redirectToBlog}>Blog</button>
          <button className="btn" onClick={redirectToStudent}>Student</button>
          <button className="btn" onClick={redirectToInstructor}>Instructor</button>
          <button className="btn" onClick={redirectToProcoord}>ProgramCoOrdinator</button>
          <button className="btn" onClick={redirectToAdmin}>Admin</button>
          <button className="btn" onClick={redirectToqa}>QA</button>
          <button className="btn" onClick={redirectToLogin}>Login</button>
          <button className="btn" onClick={redirectToSignUp}>Sign Up</button>
        </div>
      </header>
      <div className="content-part-home">
        <h1>Computer Science Program</h1>
        <p>With the help of our Computer Science program, plunge into the world of innovation and technology. Learn to code, take a variety of courses, and influence the direction of technology. Join us as we embark on a thrilling career path!</p>

        <h1>Goal of Program</h1>
        <ul>
          <li>Programming Proficiency</li>
          <li>Latest Technology Teaching</li>
          <li>Best Practices for Software Engineering</li>
          <li>Computer Ethics and Responsibility</li>
          <li>Expertise in Cybersecurity</li>
        </ul>

        <h1>Performance Measurement and Assessment</h1>
        <ul>
          <li>Continuous Improvement: Curriculum improvements are driven by ongoing reviews.</li>
          <li>High standards are made sure for programs and student results through quality assurance.</li>
          <li>Individual Progress: Students keep note of their areas of strength and development.</li>
          <li>Feedback from the instructor: Teachers modify their pedagogies to ensure student success.</li>
          <li>Data-Driven Decision-Making: Strategic program decisions are based on assessment data.</li>
        </ul>
      </div>

      <footer>
        <div className="footer-container">
          <div className="footer-left">
            <p>Syllabix</p>
          </div>
          <div className="footer-middle">
            <div className="footer-list">
              <ul>
                <li><a href="#">Home</a></li>
                <li><a href="#">About</a></li>
                <li><a href="#">Features</a></li>
                <li><Link to="/contactus">Contact Us</Link></li>
              </ul>
            </div>
            <div className="footer-list">
              <ul>
                <li><a href="#">Search</a></li>
                <li><a href="#">T&C</a></li>
                <li><a href="#">Privacy</a></li>
                <li><a href="#">FAQ</a></li>
              </ul>
            </div>
          </div>

          <div className="footer-right">
            <div className="newsletter">
              <input type="email" placeholder="Enter your email" />
              <button>Subscribe</button>
            </div>
            <div className="social-media">
              <a href="https://www.facebook.com/"><img src="./resources/facebook-icon.png" alt="Facebook" /></a>
              <a href="https://www.instagram.com/"><img src="./resources/instagram-icon.png" alt="Instagram" /></a>
              <a href="https://twitter.com/"><img src="./resources/twitter-icon.png" alt="Twitter" /></a>
              <a href="https://www.youtube.com/"><img src="./resources/youtube-icon.png" alt="YouTube" /></a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default HomePage;