import React, { useState } from 'react';
import './styles_new.css';
import { useNavigate, Link } from 'react-router-dom';



function LogIn() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
  };

  const redirectToHome = () => {
    navigate('/');
  };
  const redirectToSignUp = () => {
    navigate('/signup');
  };
  return (
    <div>
      <header>
        <div className="website-title">Syllabix</div>
        <button className="hamburger-menu" onClick={toggleMenu}>
          &#9776; {/* Hamburger icon */}
        </button>
        <div className={`header-buttons ${isMenuOpen ? 'open' : ''}`}>
          <button className="btn" onClick={redirectToHome}>
            Home
          </button>
          <button className="btn" onClick={redirectToSignUp}>
            Sign Up
          </button>
        </div>
      </header>
      <div className="content-part">
        <h1>Login</h1>
        <form>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input type="text" id="username" name="username" required />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" required />
          </div>
          <div className="form-group">
            <button className="btn" type="submit">
              Log In
            </button>
          </div>
          <div className="form-group">
            <p>
              <Link to="/forgetpwd">Forget your password?</Link>
            </p>
          </div>
          <div className="form-group">
            <button className="btn" type="submit" onClick={redirectToSignUp}>
              Sign Up
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LogIn;