import React, { useState } from 'react';
import './styles_new.css';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const redirectToHome = () => {
        navigate('/');
      };
      const redirectToLogin = () => {
        navigate('/login');
      };
      const redirectToSignUp = () => {
        navigate('/signup');
      };
  return (
    <div>
      <header>
                <div className="website-title">Syllabix</div>
                <button className="hamburger-menu" onClick={toggleMenu}>
                    &#9776; {/* Hamburger icon */}
                </button>
                <div className={`header-buttons ${isMenuOpen ? 'open' : ''}`}>
                    <button className="btn" onClick={redirectToHome}>Home</button>
                    <button className="btn" onClick={redirectToLogin}>LogIn</button>
                    <button className="btn" onClick={redirectToSignUp}>Sign Up</button>
                    {/* Add additional menu items here */}
                </div>
            </header>
      <div className="content-part">
        <h1>Forgot Password</h1>
        <form>
          <div className="form-group">
            <label htmlFor="email">Enter Email Address</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <button className="btn" type="submit">Reset Password</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
